import React from 'react'
import loadable from '@loadable/component'
import Cookies from '../components/cookies/cookies'
import PrimaryLayout from '../layouts/primary'
const Header = loadable(() => import('../components/header/header'))


const ComplaintsProcedure = () => {


    return (
        <PrimaryLayout pageTitle="Complaints - My Policy Vault">
            <Header />
            <Cookies type="complaints" />
        </PrimaryLayout>
    )


}

export default ComplaintsProcedure