import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import loadable from '@loadable/component'

import Icons from 'uikit/dist/js/uikit-icons';
import './index.scss'
import 'uikit/dist/css/uikit.css'
import UIkit from 'uikit'

const Seo = loadable(() => import("../components/seo/seo.js"))
const Navbar = loadable(() => import("../components/navbar/navbar.js"))
const Footer = loadable(() => import("../components/footer/footer.js"))
// const CookieConsent = loadable(() => import("react-cookie-consent"))



const Layout = ({ children, pageData, pageTitle }) => {



    UIkit.use(Icons);

    return (

        <>
            <Seo title={pageTitle}></Seo>
            <Navbar navBg="false" />
            <main>{children}</main>
            <Footer displayBanner={false}></Footer>
            
            {/* <CookieConsent
                // debug="true"
                location="bottom"
                cookieName={checkTheme().cookie}
                style={{ background: "#5e5e5e", color: "#fff" }}
                buttonStyle={{ background: checkTheme().color, color: "#fff" }}
                expires={150}>
                This website is using cookies to deliver you a better user experience.
                <Link to="/privacy-cookie-policy" style={{ color: "#fff", textDecoration: "underline" }}>
                    Find out more</Link>
            </CookieConsent> */}
        </>

    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
